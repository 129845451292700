import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

import moment from 'moment';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';
import MoneyInput from '@uiinputs/MoneyInput';

import Association from '@models/general/Association';
import PricingElement from '@models/pricing/PricingElement';

class PricingElementsInput extends Component {
  state = {
    pricingElements: this.props.pricingElements
      ? this.props.pricingElements
      : this.props.defaultEnabled
      ? [new PricingElement()]
      : [],
    pricingElementCreated: false,
  };

  componentDidUpdate() {
    if (this.state.pricingElements.length > 0 && !this.state.stepsDisplayed) {
      setTimeout(() => {
        this.setState({ stepsEnabled: true, stepsDisplayed: true });
      }, 500);
    }
    const { createPriceElement } = this.props;

    if (createPriceElement && !this.state.pricingElementCreated) {
      this.addNewPricingElement();
      this.setState({ pricingElementCreated: true });
    }
  }
  onChange = (pricingElements) => {
    const { onChange } = this.props;
    onChange && onChange(pricingElements);
  };

  addNewPricingElement = () => {
    const { pricingElements } = this.state;
    this.setState({
      pricingElements: [...pricingElements, new PricingElement()],
    });
  };

  updatePricingElement = (newPricingElement, index) => {
    const { pricingElements } = this.state;
    const newPricingElements = [...pricingElements].sort((a, b) =>
      moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt))
    );
    newPricingElements[index] = newPricingElement;

    this.setState({
      pricingElements: newPricingElements,
    });

    this.onChange(newPricingElements);
  };

  render() {
    const { t, user, currencies, pricingCategories } = this.props;
    const { pricingElements } = this.state;

    const pricingCategoriesOptions = pricingCategories.map((pc) => ({
      label: pc.name,
      value: pc.id,
      pricingCategory: pc,
    }));

    return (
      <>
        {[...pricingElements]
          .sort((a, b) => moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt)))
          .map((pricingElement, index) => (
            <div className="list-sector" key={`input-pricingElement-${index}`}>
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newPricingElements = [...pricingElements];
                    newPricingElements.splice(index, 1);

                    this.setState({
                      pricingElements: newPricingElements,
                    });
                    this.onChange(newPricingElements);
                  }}
                />
              </div>
              <div className="input-group no-margin-top flex-container">
                <div className="input-group one no-margin-top">
                  <div className="input-group no-margin-top selector">
                    <Select
                      options={pricingCategoriesOptions}
                      placeholder={t('form.label.pricingCategory')}
                      value={pricingCategoriesOptions.find(
                        (option) => option.value === pricingElement?.category?.id
                      )}
                      noOptionsMessage={() => t('noOptions')}
                      onChange={(event) => {
                        const newPricingElement = { ...pricingElement };
                        newPricingElement.category = event.pricingCategory;

                        this.updatePricingElement(newPricingElement, index);
                      }}
                    />
                  </div>
                </div>
                <div className="input-group four no-margin-top">
                  <div className="input-group no-margin-top">
                    <MoneyInput
                      currencies={currencies}
                      user={user}
                      value={pricingElement.amount}
                      onChange={(newValue) => {
                        const newPricingElement = { ...pricingElement };
                        newPricingElement.amount = newValue;

                        this.updatePricingElement(newPricingElement, index);
                      }}
                    />
                  </div>
                </div>
              </div>
              {!pricingElement.remarksVisible && !pricingElement.description && (
                <div className="input-group">
                  <div
                    className="as-link"
                    onClick={(e) => {
                      e.preventDefault();
                      this.updatePricingElement({ ...pricingElement, remarksVisible: true }, index);
                    }}
                  >
                    {t('form.label.addRemark')}
                  </div>
                </div>
              )}
              {(pricingElement.remarksVisible || pricingElement.description) && (
                <FormInput
                  type="textarea"
                  label="form.label.description"
                  className="small"
                  value={pricingElement.description}
                  onChange={(event) => {
                    const newPricingElement = { ...pricingElement };
                    newPricingElement.description = event.target.value;

                    this.updatePricingElement(newPricingElement, index);
                  }}
                />
              )}
            </div>
          ))}
        <div className={`input-group left${pricingElements.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewPricingElement(e)}>
            {t('form.label.addPricingElement')}
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currencies: state.platform.currencies,
    pricingCategories: state.settings.variables.pricingCategories,
  };
};

export default connect(mapStateToProps)(withTranslation('translation')(PricingElementsInput));
