import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import BusinessInput from '@components/business/BusinessInput';
import FlatIdentifierInput from '@components/general/crud/FlatIdentifierInput';
import MessageAutomationInput from '@components/messageAutomation/crud/MessageAutomationInput';

import Collapsible from '@uicomponents/Collapsible';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class AdministrationForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formAdministration, setHasChanged } = this.props;
    onSubmit && onSubmit(formAdministration);
    setHasChanged(false);
  }

  render() {
    const { onChange, t, formAdministration, setHasChanged } = this.props;

    return (
      <div
        className="step-form"
        key={`${formAdministration.id}-${formAdministration.versionNumber}-${formAdministration.nonce}`}
      >
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.name"
                required={true}
                value={formAdministration.name}
                onChange={(event) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.name = event.target.value;

                  onChange('formAdministration', newAdministration, event);
                }}
              />
              {this.validator.message(
                t('form.label.name'),
                formAdministration.name,
                'required|string'
              )}
            </div>
            <div className="input-group">
              <BusinessInput
                key={formAdministration.business ? formAdministration.business : null}
                value={formAdministration.business ? formAdministration.business : null}
                onChange={(newBusiness) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.business = { ...newBusiness };

                  onChange('formAdministration', newAdministration, event);
                }}
              />
              {this.validator.message(
                t('form.label.business'),
                formAdministration.business,
                'required'
              )}
            </div>
            <div>
              <div className="input-group no-margin-top  half">
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.numberFormat"
                  required={true}
                  value={formAdministration.numberFormat}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.numberFormat = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.numberFormat'),
                  formAdministration.numberFormat,
                  'required|string'
                )}
              </div>
              <div className="input-group no-margin-top half">
                <FormInput
                  type="number"
                  setHasChanged={setHasChanged}
                  label="form.label.numberLength"
                  required={true}
                  value={formAdministration.numberLength}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.numberLength = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.numberLength'),
                  formAdministration.numberFormat,
                  'required|number'
                )}
              </div>
            </div>
            <div>
              <FormInput
                type="number"
                setHasChanged={setHasChanged}
                label="form.label.dueDays"
                value={formAdministration.dueDays}
                required={true}
                onChange={(event) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.dueDays = event.target.value;

                  onChange('formAdministration', newAdministration, event);
                }}
              />
              {this.validator.message(
                t('form.label.dueDays'),
                formAdministration.dueDays,
                'required|number'
              )}
            </div>
            <FlatIdentifierInput
              entityType="administration"
              entity={formAdministration}
              onChange={(newIdentifiers) => {
                const newAdministration = { ...formAdministration };
                newAdministration.identifiers = newIdentifiers;

                onChange('formAdministration', newAdministration);
              }}
            />

            <Collapsible
              className="input-group"
              name={t('administration.invoiceMessageAutomations')}
              defaultIsOpen={false}
            >
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectInvoiceSendMessageAutomation')}
                  value={formAdministration.invoiceSendMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceSendMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectInvoiceFirstReminderMessageAutomation')}
                  value={formAdministration.invoiceFirstReminderMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceFirstReminderMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectInvoiceSecondReminderMessageAutomation')}
                  value={formAdministration.invoiceSecondReminderMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceSecondReminderMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectInvoiceThirdReminderMessageAutomation')}
                  value={formAdministration.invoiceThirdReminderMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceThirdReminderMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectInvoicePaidMessageAutomation')}
                  value={formAdministration.invoicePaidMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoicePaidMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div>
                <FormInput
                  type="textarea"
                  setHasChanged={setHasChanged}
                  label="form.label.invoiceTermsAndConditions"
                  required={true}
                  className="small"
                  value={formAdministration.invoiceTermsAndConditions}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.invoiceTermsAndConditions = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
              </div>
            </Collapsible>
            <Collapsible
              className="input-group"
              name={t('administration.quoteMessageAutomations')}
              defaultIsOpen={false}
            >
              <div className="input-group">
                <MessageAutomationInput
                  placeholder={t('form.label.selectQuoteMessageAutomation')}
                  value={formAdministration.quoteSendMessageAutomation}
                  onChange={(newMessageAutomation) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.quoteSendMessageAutomation = newMessageAutomation;

                    onChange('formAdministration', newAdministration);
                  }}
                />
              </div>
              <div>
                <FormInput
                  type="textarea"
                  setHasChanged={setHasChanged}
                  label="form.label.quoteTermsAndConditions"
                  required={true}
                  className="small"
                  value={formAdministration.quoteTermsAndConditions}
                  onChange={(event) => {
                    const newAdministration = { ...formAdministration };
                    newAdministration.quoteTermsAndConditions = event.target.value;

                    onChange('formAdministration', newAdministration, event);
                  }}
                />
              </div>
            </Collapsible>

            <div className="input-group more">
              <div>{t('form.label.primary')}</div>
              <Switch
                checked={formAdministration.primary}
                onChange={(e, newValue) => {
                  const newAdministration = { ...formAdministration };
                  newAdministration.primary = newValue;

                  onChange('formAdministration', newAdministration, event);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => e.stopPropagation()}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
