import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateMessageAutomationPage from '@components/messageAutomation/crud/CreateMessageAutomationPage.js';

import * as fieldActions from '@actions/fieldActions';
import * as generalActions from '@actions/generalActions';
import * as messageAutomationActions from '@actions/messageAutomationActions';
import * as navigationActions from '@actions/navigationActions';

class CreateMessageAutomationContainer extends PureComponent {
  render() {
    const { changeValue, createMessageAutomation, popStack, callback, ...props } = this.props;

    return (
      <CreateMessageAutomationPage
        {...this.props}
        onChange={changeValue}
        createMessageAutomation={(messageAutomation) =>
          createMessageAutomation(messageAutomation).then((response) => {
            popStack?.();
            popStack?.();
            callback?.(response.messageAutomation);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    darkMode: state.settings.darkMode,
    user: state.auth.user,
    dataTypes: state.platform.dataTypes,
    messageAutomationChannels: state.platform.messageAutomationChannels,

    ...state.messageAutomation,
    fields: state.platform.fields,
    isFetchingFields: state.platform.isFetchingFields,

    entityStructure: state.platform.entityStructure,
    triggerEvents: state.platform.triggerEvents,

    languages: state.platform.languages,
    settings: state.settings.settings,

    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMessageAutomation: (messageAutomation) =>
      dispatch(messageAutomationActions.createMessageAutomation(messageAutomation)),
    changeValue: (name, value) => dispatch(messageAutomationActions.changeValue(name, value)),

    getFieldsRequest: (entityType) => dispatch(fieldActions.getFieldsRequest(entityType)),
    searchFieldsRequest: (entityType, keyword, limit) =>
      dispatch(fieldActions.searchFieldsRequest(entityType, keyword, limit)),

    updateTopStack: (args) => dispatch(navigationActions.updateTopStack(args)),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),

    getEntityStructure: (entityType) => dispatch(generalActions.getEntityStructure(entityType)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateMessageAutomationContainer));
