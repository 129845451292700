import { combineReducers } from 'redux';

import action from './action';
import actor from './actor';
import administration from './administration';
import app from './app';
import attribute from './attribute';
import auth from './auth';
import ban from './ban';
import boardComputer from './boardComputer';
import business from './business';
import capacity from './capacity';
import capacityContract from './capacityContract';
import card from './card';
import certificate from './certificate';
import chat from './chat';
import chauffeur from './chauffeur';
import chauffeurTimeslot from './chauffeurTimeslot';
import complaint from './complaint';
import consignment from './consignment';
import contract from './contract';
import event from './event';
import feedback from './feedback';
import filter from './filter';
import fleet from './fleet';
import good from './good';
import integration from './integration';
import location from './location';
import mail from './mail';
import management from './management';
import messageAutomation from './messageAutomation';
import messageTemplate from './messageTemplate';
import navigation from './navigation';
import notification from './notification';
import plan from './plan';
import platform from './platform';
import pricing from './pricing';
import pricingVariableIndex from './pricingVariableIndex';
import publisher from './publisher';
import saml from './saml';
import scheduleRoute from './scheduleRoute';
import sensor from './sensor';
import settings from './settings';
import support from './support';
import team from './team';
import tender from './tender';
import tenderBid from './tenderBid';
import terminal from './terminal';
import tire from './tire';
import todo from './todo';
import transportEquipment from './transportEquipment';
import transportOrder from './transportOrder';
import trip from './trip';
import type from './type';
import user from './user';
import vehicle from './vehicle';
import vehicleGroup from './vehicleGroup';
import widget from './widget';

export default combineReducers({
  attribute,
  auth,
  user,
  notification,
  chat,
  platform,
  support,
  navigation,
  settings,
  filter,

  action,
  actor,
  ban,
  business,
  card,
  chauffeur,
  complaint,
  consignment,
  contract,
  certificate,
  vehicle,
  vehicleGroup,
  fleet,
  transportOrder,
  trip,
  type,
  saml,
  sensor,
  event,
  mail,
  location,
  messageTemplate,
  management,
  integration,
  widget,
  tender,
  tenderBid,
  terminal,
  pricing,
  pricingVariableIndex,
  transportEquipment,
  administration,
  boardComputer,
  team,
  todo,
  messageAutomation,
  capacity,
  feedback,
  capacityContract,
  scheduleRoute,
  chauffeurTimeslot,
  plan,
  app,
  publisher,
  tire,
});
