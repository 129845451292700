import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import CloseButton from '@uicomponents/CloseButton';

import Constraint from '@models/constraint/Constraint';
import Association from '@models/general/Association';

import ConstraintForm from './ConstraintForm';

class ConstraintsInput extends Component {
  static defaultProps = {
    enabledOptions: [
      'andConstraint',
      'orConstraint',
      'notConstraint',
      'timeWindowsConstraint',
      'startDateTimeConstraint',
      'endDateTimeConstraint',
      'sizeConstraint',
      'weightConstraint',
      'speedConstraint',
      'sensorValueConstraint',
      'fuelTypeConstraint',
      'vehicleTypeConstraint',
      'transportEquipmentTypeConstraint',
      'routeConstraint',
      'emissionStandardConstraint',
    ],
  };
  state = {
    constraints: this.props.constraints || [new Association('inline', new Constraint())],
    constraintCreated: false,
  };

  componentDidUpdate() {
    const { createLimitation } = this.props;

    if (createLimitation && !this.state.constraintCreated) {
      this.addNewConstraint();
      this.setState({ constraintCreated: true });
    }
  }

  addNewConstraint = () => {
    const { onChange } = this.props;
    const { constraints } = this.state;

    this.setState(
      {
        constraints: [...constraints, new Association('inline', new Constraint())],
      },
      () => onChange(this.state.constraints)
    );
  };

  updateConstraint = (newConstraint, index) => {
    const { onChange } = this.props;

    const { constraints } = this.state;
    const newConstraints = [...constraints];
    const newAssociation = { ...newConstraints[index] };
    newAssociation.entity = newConstraint;
    newConstraints[index] = newAssociation;

    this.setState({
      constraints: newConstraints,
    });

    onChange && onChange(newConstraints);
  };

  render() {
    const { t, onChange, enabledOptions } = this.props;
    const { constraints } = this.state;

    return (
      <>
        {constraints
          .map((association) => association.entity)
          .map((constraint, index) => (
            <div className="list-sector constraint-form-element" key={`input-constraint-${index}`}>
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const newConstraints = [...constraints];
                    newConstraints.splice(index, 1);

                    this.setState({
                      constraints: newConstraints,
                    });
                    onChange && onChange(newConstraints);
                  }}
                />
              </div>
              <ConstraintForm
                enabledOptions={enabledOptions}
                constraint={constraint}
                onChange={(newConstraint) => {
                  this.updateConstraint(newConstraint, index);
                }}
              />
            </div>
          ))}
        <div className={`input-group left${constraints.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewConstraint(e)}>
            {t('form.label.addConstraint')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ConstraintsInput);
