import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateMessageAutomationContainer from '@containers/messageAutomation/crud/CreateMessageAutomationContainer';

import { search as searchMessageAutomations } from '@api/messageAutomationApi';

import * as messageAutomationActions from '@actions/messageAutomationActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformMessageAutomationToValue = (messageAutomation) => ({
  value: messageAutomation.id,
  label: messageAutomation.name,
  id: messageAutomation.id,
  messageAutomation: messageAutomation,
});

class MessageAutomationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageAutomation: this.props.value
        ? transformMessageAutomationToValue(this.props.value)
        : null,
      searchParameters: this.props.searchParameters ? this.props.searchParameters : {},
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  onChange = (newMessageAutomation) => {
    this.setState({
      messageAutomation: newMessageAutomation,
    });
    const { onChange } = this.props;
    onChange && onChange(newMessageAutomation ? newMessageAutomation.messageAutomation : null);
  };

  render() {
    const { t, placeholder, isFetchingMessageAutomations, addToStack, isDisabled } = this.props;
    const { messageAutomation, searchParameters } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={placeholder ? placeholder : `${t('form.label.selectMessageAutomation')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingMessageAutomations}
          isDisabled={isDisabled || isFetchingMessageAutomations}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchMessageAutomations({ query: inputValue, ...searchParameters }, 0, 40).then(
                (response) => {
                  callback(
                    response.messageAutomations.map((messageAutomation) =>
                      transformMessageAutomationToValue(messageAutomation)
                    )
                  );
                }
              );
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('messageAutomation.new'),
              component: (
                <CreateMessageAutomationContainer
                  targetName={inputValue}
                  callback={(newMessageAutomation) => {
                    this.onChange(transformMessageAutomationToValue(newMessageAutomation));
                  }}
                />
              ),
            });
          }}
          value={messageAutomation}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.messageAutomation,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(messageAutomationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(MessageAutomationInput));
