import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';

const convertBusinessToValue = (t, business, customerFor) => ({
  id: business.id,
  value: business.id,
  label: `${business.name}${
    customerFor
      ? ` ${t('form.label.customerFor_lowercased', {
          business: customerFor,
        })}`
      : ''
  }${
    business.pricingConfigurationCount > 0
      ? ` (${t('business.hasPricingConfiguration', { count: business.pricingConfigurationCount })})`
      : ''
  }`,
  business: { ...business },
});

class CustomerInput extends Component {

  static defaultOptions = {
    simpleForms: false
  }
  
  constructor(props) {
    super(props);

    const { customer } = props;
    this.searchTimeout = null;
    this.state = {
      businesses: [],
      customer:
        customer && customer.business
          ? {
              ...customer,
              business: {
                business: customer.business.label ? customer.business.business : customer.business,
                label: customer.business
                  ? customer.business.label
                    ? customer.business.label
                    : customer.business.name
                  : null,
                value: customer.business.id,
                id: customer.business.id,
              },
            }
          : {
              business: null,
              customer: null,
            },
    };
  }

  onChange = (newCustomer) => {
    const { onChange } = this.props;
    onChange && onChange(newCustomer);
  };

  sortByLabel = (values) => values.sort((a, b) => a.label.localeCompare(b.label));

  componentDidMount() {
    const { searchBusinesses } = this.props;

    searchBusinesses({}, 0, 40).then((response) => {
      this.setState({
        businesses: response.businesses,
      });
    });
  }

  render() {
    const { t, searchBusinesses, placeholder, addToStack, customerFor, simpleForms } = this.props;
    const { customer, businesses } = this.state;

    return (
      <>
        <div
          className="input-group no-margin-top"
          key={`${customer.business && customer.business.id}-${
            customer.business && customer.business.nonce
          }`}
        >
          <div className="input-group no-margin-top">
            <AsyncCreatableSelect
              isClearable
              noOptionsMessage={() => t('businesses.notFound')}
              placeholder={placeholder ? placeholder : t('form.label.selectCustomer')}
              loadingMessage={() => t('loading')}
              defaultOptions={this.sortByLabel(
                businesses.map((business) => convertBusinessToValue(t, business, customerFor))
              )}
              loadOptions={(inputValue, callback) => {
                if (inputValue && inputValue != '') {
                  if (this.searchTimeout) clearTimeout(this.searchTimeout);
                  this.searchTimeout = setTimeout(() => {
                    searchBusinesses({ query: inputValue }, 0, 40).then((response) => {
                      callback(
                        response.businesses.map((business) =>
                          convertBusinessToValue(t, business, customerFor)
                        )
                      );
                    });
                  }, 400);
                } else {
                  callback(businesses.map((business) => convertBusinessToValue(business)));
                }
              }}
              value={customer.business ? customer.business : null}
              onChange={(e) => {
                const newCustomer = { ...customer };
                newCustomer.business = e ? { ...e } : null;

                this.onChange(newCustomer);
                this.setState({
                  customer: newCustomer,
                });
              }}
              onCreateOption={(inputValue) => {
                addToStack({
                  name: t('business.new'),
                  component: (
                    <CreateBusinessContainer
                      targetName={inputValue}
                      callback={(data) => {
                        const newCustomer = { ...customer };
                        newCustomer.business = {
                          id: data.business.id,
                          value: data.business.id,
                          label: data.business.name,
                          business: data.business,
                        };

                        this.onChange(newCustomer);
                        this.setState({
                          customer: newCustomer,
                        });
                      }}
                    />
                  ),
                });
              }}
            />
          </div>
        </div>
        {customer.business && customer.business.business && !simpleForms && (
          <div className="input-group">
            <CustomerInput
              {...this.props}
              placeholder={t('form.label.customerFor', {
                business: customer.business.business.name,
              })}
              customerFor={customer.business.business.name}
              customer={customer.customer}
              onChange={(business) => {
                const newCustomer = { ...customer };
                newCustomer.customer = business;

                this.setState({
                  customer: newCustomer,
                });

                this.onChange(newCustomer);
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(CustomerInput);
