import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import jsonBeautify from 'json-beautify';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import IntegrationFileFieldSelectorInput from './IntegrationFileFieldSelectorInput';

class IntegrationFileFieldSelectorsInput extends Component {
  state = {
    fields: this.props.value ? this.props.value : [],
    jsonMode: false,
    json: {},
  };

  addFieldSelector = () => {
    const { onChange } = this.props;
    const { fields } = this.state;

    this.setState(
      {
        fields: [...fields, {}],
      },
      () => {
        onChange && onChange(this.state.fields);
      }
    );
  };

  render() {
    const { t, onChange, className, topLevel } = this.props;
    const { fields, jsonMode, json } = this.state;

    return (
      <>
        {jsonMode ? (
          <div className="input-container">
            <FormInput
              type="textarea"
              wrapperClass="no-margin-top"
              value={jsonBeautify(JSON.parse(json), null, 2, 100)}
              onChange={(e) => {
                e.preventDefault();
                this.setState({
                  json: e.target.value,
                });
              }}
            />
            {topLevel && (
              <div className="input-group one right">
                <div>{t('form.label.json')}</div>
                <Switch
                  checked={jsonMode}
                  onChange={(e) => {
                    this.setState({
                      jsonMode: !jsonMode,
                      json: JSON.stringify(fields),
                    });

                    this.setState(
                      {
                        fields: jsonMode ? JSON.parse(json) : fields,
                      },
                      () => {
                        onChange && onChange(this.state.fields);
                      }
                    );
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={`${className}`}>
            <div className={` scrollable scrollable__x`} style={{ minHeight: 200, maxHeight: 600 }}>
              {fields.map((fieldSelector, index) => (
                <IntegrationFileFieldSelectorInput
                  key={`file-field-${index}`}
                  value={{ ...fieldSelector }}
                  onChange={(newFieldSelector) => {
                    const newFieldSelectors = [...fields];
                    newFieldSelectors[index] = newFieldSelector;

                    this.setState(
                      {
                        fields: newFieldSelectors,
                      },
                      () => {
                        onChange && onChange(this.state.fields);
                      }
                    );
                  }}
                  onDelete={(e) => {
                    const newFieldSelectors = [...fields];
                    newFieldSelectors.splice(index, 1);

                    this.setState(
                      {
                        fields: newFieldSelectors,
                      },
                      () => {
                        onChange && onChange(this.state.fields);
                      }
                    );
                  }}
                />
              ))}
            </div>
            <div className="input-group flex-container">
              <div className={`input-group one left`}>
                <button type="button" onClick={(e) => this.addFieldSelector(e)}>
                  {t('form.label.addField')}
                </button>
              </div>
              {topLevel && (
                <div className="input-group one right">
                  <div>{t('form.label.json')}</div>
                  <Switch
                    checked={jsonMode}
                    onChange={(e) => {
                      this.setState({
                        jsonMode: !jsonMode,
                        json: JSON.stringify(fields),
                      });

                      this.setState(
                        {
                          fields: jsonMode ? JSON.parse(json) : fields,
                        },
                        () => {
                          onChange && onChange(this.state.fields);
                        }
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(IntegrationFileFieldSelectorsInput);
