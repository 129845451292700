export const messageAutomationConstants = {
  MESSAGE_AUTOMATION_CHANGE_VALUE: 'MESSAGE_AUTOMATION_CHANGE_VALUE',

  GET_MESSAGE_AUTOMATION_STARTED: 'GET_MESSAGE_AUTOMATION_STARTED',
  GET_MESSAGE_AUTOMATION_SUCCESS: 'GET_MESSAGE_AUTOMATION_SUCCESS',
  GET_MESSAGE_AUTOMATION_FAILURE: 'GET_MESSAGE_AUTOMATION_FAILURE',

  GET_MESSAGE_AUTOMATIONS_STARTED: 'GET_MESSAGE_AUTOMATIONS_STARTED',
  GET_MESSAGE_AUTOMATIONS_SUCCESS: 'GET_MESSAGE_AUTOMATIONS_SUCCESS',
  GET_MESSAGE_AUTOMATIONS_FAILURE: 'GET_MESSAGE_AUTOMATIONS_FAILURE',

  SEARCH_MESSAGE_AUTOMATIONS_STARTED: 'SEARCH_MESSAGE_AUTOMATIONS_STARTED',
  SEARCH_MESSAGE_AUTOMATIONS_SUCCESS: 'SEARCH_MESSAGE_AUTOMATIONS_SUCCESS',
  SEARCH_MESSAGE_AUTOMATIONS_FAILURE: 'SEARCH_MESSAGE_AUTOMATIONS_FAILURE',

  CREATING_MESSAGE_AUTOMATION_STARTED: 'CREATING_MESSAGE_AUTOMATION_STARTED',
  CREATING_MESSAGE_AUTOMATION_SUCCESS: 'CREATING_MESSAGE_AUTOMATION_SUCCESS',
  CREATING_MESSAGE_AUTOMATION_FAILURE: 'CREATING_MESSAGE_AUTOMATION_FAILURE',

  UPDATING_MESSAGE_AUTOMATION_STARTED: 'UPDATING_MESSAGE_AUTOMATION_STARTED',
  UPDATING_MESSAGE_AUTOMATION_SUCCESS: 'UPDATING_MESSAGE_AUTOMATION_SUCCESS',
  UPDATING_MESSAGE_AUTOMATION_FAILURE: 'UPDATING_MESSAGE_AUTOMATION_FAILURE',

  DELETE_MESSAGE_AUTOMATION_STARTED: 'DELETE_MESSAGE_AUTOMATION_STARTED',
  DELETE_MESSAGE_AUTOMATION_SUCCESS: 'DELETE_MESSAGE_AUTOMATION_SUCCESS',
  DELETE_MESSAGE_AUTOMATION_FAILURE: 'DELETE_MESSAGE_AUTOMATION_FAILURE',

  GET_MESSAGE_AUTOMATION_TEMPLATES_STARTED: 'GET_MESSAGE_AUTOMATION_TEMPLATES_STARTED',
  GET_MESSAGE_AUTOMATION_TEMPLATES_SUCCESS: 'GET_MESSAGE_AUTOMATION_TEMPLATES_SUCCESS',
  GET_MESSAGE_AUTOMATION_TEMPLATES_FAILURE: 'GET_MESSAGE_AUTOMATION_TEMPLATES_FAILURE',

  CREATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS: 'CREATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS',
  UPDATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS: 'UPDATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS',
};
