export default class MessageAutomation {
  id;
  uuid;

  name;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);
  }
}
