import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class EmissionsClassSelector extends Component {
  render() {
    const { t, onChange, placeholder, value, isMulti } = this.props;
    const options = [
      { label: t('EURO_0'), value: 'euro0' },
      { label: t('EURO_1'), value: 'euro1' },
      { label: t('EURO_2'), value: 'euro2' },
      { label: t('EURO_3'), value: 'euro3' },
      { label: t('EURO_4'), value: 'euro4' },
      { label: t('EURO_5'), value: 'euro5' },
      { label: t('EURO_6'), value: 'euro6' },
      { label: t('EURO_7'), value: 'euro7' },
      { label: t('EURO_EEV'), value: 'euroeev' },
    ];
    return (
      <Select
        isMulti={isMulti}
        value={isMulti ? options.filter(option => value && value.includes(option.value)) : options.find(option => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(EmissionsClassSelector);
