import React, { Component } from 'react';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import AvatarPicker from '@uicomponents/AvatarPicker';
import FormInput from '@uiinputs/FormInput';

import Chat from '@models/chat/Chat';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { base64ToBlob } from '@utils/imageUtils';

export default class UpdateChatPage extends Component {
  state = {
    inputValue: '',
    logo: this.props.formChat?.image?.url || '/images/group.svg',
    logoPicker: false,
  };
  searchTimeout = null;

  componentDidMount() {
    const { onChange } = this.props;
    activateInputs();

    onChange('chat', new Chat());
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { formChat, updateChat, popStack } = this.props;
    formChat.users.length > 0 && updateChat && updateChat(formChat);
    popStack();
  }

  render() {
    const { t, formChat, onChange, searchUsers, uploadFile } = this.props;
    const { Option } = components;
    const { logo, logoPicker } = this.state;

    const SelectOption = (props) => (
      <Option {...props} className="select-option">
        <img src={props.data.avatar} alt={props.data.label} />
        <span>{props.data.label}</span>
      </Option>
    );
    const SelectValue = (props) => (
      <div className="select-value">
        <img src={props.data.avatar} alt={props.data.label} />
        {props.data.label}
      </div>
    );

    return (
      <div className="step-form">
        <AvatarPicker
          {...this.props}
          title={t('form.uploadLogo')}
          active={logoPicker}
          handleSubmit={(croppedImage) => {
            this.setState({
              logo: croppedImage,
              logoPicker: !logoPicker,
            });
            uploadFile(base64ToBlob(croppedImage), 'files').then((uploadedLogo) => {
              const newChat = { ...formChat };
              newChat.image = uploadedLogo.file;
              onChange('formChat', newChat);
            });
          }}
          onClick={() => this.setState({ logoPicker: !logoPicker })}
          onClose={() => this.setState({ logoPicker: !logoPicker })}
        />
        <form className="form active">
          <div className="input-group no-margin-top">
            <img className="profile-image" src={logo || formChat?.image?.url} alt="picture" />
            <label
              className="picture"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  logoPicker: !logoPicker,
                });
              }}
            >
              {t('form.label.choosePicture')}
            </label>
          </div>

          <FormInput
            type="text"
            label="form.label.groupName"
            value={formChat?.title}
            onChange={(event) => {
              const newChat = { ...formChat };
              newChat.title = event.target.value;
              onChange('formChat', newChat);
            }}
          />

          <div className="input-group">
            <AsyncSelect
              isMulti={true}
              components={{
                Option: SelectOption,
                SingleValue: SelectValue,
              }}
              noOptionsMessage={() => t('users.notFound')}
              placeholder={t('form.label.addPeople')}
              loadingMessage={() => t('loading')}
              loadOptions={(inputValue, callback) => {
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                  searchUsers({ query: inputValue }, 0, 20).then((data) => {
                    callback(
                      data.users.map((user) => {
                        return {
                          user: user,
                          value: user.id,
                          label: `${user.firstName} ${user.lastName}`,
                          avatar: user.avatar ? user.avatar.url : '/images/user.svg',
                        };
                      })
                    );
                  });
                }, 800);
              }}
              onChange={(e) => {
                const newChat = { ...formChat };
                newChat.users = e;
                onChange('formChat', newChat);
              }}
              value={
                formChat?.users?.length
                  ? formChat.users.map((item) => ({
                      user: item.user,
                      label: `${item.user.firstName} ${item.user.lastName}`,
                      value: item.user.id,
                      avatar: item.user.avatar ? item.user.avatar.url : '/images/user.svg',
                    }))
                  : []
              }
            />
          </div>

          <div className="input-group more right">
            <input type="submit" onClick={(e) => this.handleSubmit(e)} value={t('form.save')} />
          </div>
        </form>
      </div>
    );
  }
}
