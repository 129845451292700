import React, { Component } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';

import ActionInput from '@components/action/ActionInput';
import ChauffeurInput from '@components/chauffeur/ChauffeurInput';
import ConsignmentInput from '@components/consignment/ConsignmentInput';
import TransportEquipmentInput from '@components/goods/TransportEquipmentInput';
import BoardInput from '@components/todo/board/BoardInput';
import TransportOrderInput from '@components/transportOrder/TransportOrderInput';
import TripInput from '@components/trip/TripInput';
import VehicleInput from '@components/vehicle/VehicleInput';

import Attachments from '@uicomponents/Attachments';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class TodoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.validator = new SimpleReactValidator();
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleValue = (description) => {
    if (description) {
      const blocksFromHtml = htmlToDraft(description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);

      this.setState({
        editorState,
      });
    }
  };

  onChangeHandler = (html) => {
    const { changeValue, todoForm } = this.props;
    const newTodoForm = { ...todoForm };
    newTodoForm.description = html;
    changeValue('todoForm', newTodoForm);
  };

  todoAssignees() {
    const { users } = this.props;
    return users.map((user) => {
      return {
        label: `${user.username} | ${user.firstName} ${user.lastName}`,
        value: user.id,
        id: user.id,
      };
    });
  }

  createTodo(e) {
    e.preventDefault();
    const { lane, board, createTodo, todoForm, popStack, todoCallback } = this.props;

    if (todoForm.lane) {
      createTodo &&
        createTodo(todoForm.lane.id, todoForm).then((resp) => {
          if (resp.success) {
            todoCallback && todoCallback();
          }
        });
    } else if (!lane && board) {
      createTodo(board.lanes.filter((lane) => lane.position === 0).pop().id, todoForm).then(
        (resp) => {
          if (resp.success) {
            todoCallback && todoCallback();
          }
        }
      );
    }

    popStack();
  }

  updateTodo(e) {
    e.preventDefault();
    const { updateTodo, todoForm, popStack, todoCallback } = this.props;
    updateTodo &&
      updateTodo(todoForm).then((resp) => {
        if (resp.success) {
          todoCallback && todoCallback();
        }
      });
    popStack();
  }

  componentDidMount() {
    const { getUsers, todoForm } = this.props;

    getUsers(0, 30);
    activateInputs();
    this.handleValue(todoForm?.description);
  }

  componentDidUpdate(prevProps) {
    const { shouldUpdateContent, todoForm } = this.props;
    if (
      prevProps.todoForm?.description !== todoForm?.description &&
      shouldUpdateContent &&
      prevProps.shouldUpdateContent !== shouldUpdateContent
    ) {
      this.handleValue(todoForm?.description);
    }
    activateInputs();
  }

  componentWillUnmount() {
    const { changeValue, setLane } = this.props;
    setLane(null);
    changeValue('todoForm', null);
    changeValue('isEdit', false);

    deactivateInputs();
  }

  getActionType = (actionType) => {
    switch (actionType) {
      case 'action':
        return 'genericAction';
      case 'loadAction':
        return 'load';
      case 'stopAction':
        return 'stop';
      case 'unloadAction':
        return 'unload';
    }
  };

  relatedEntityInput = () => {
    const { todoForm, relatedEntity } = this.props;

    switch (todoForm?.relatedEntityType) {
      case 'vehicle':
        return (
          <VehicleInput
            {...this.props}
            vehicle={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newVehicle) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newVehicle?.id;
              newTodoForm.relatedEntityName = newVehicle?.name;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'transportOrder':
        return (
          <TransportOrderInput
            {...this.props}
            value={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newTransportOrder) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newTransportOrder?.id;
              newTodoForm.relatedEntityName = newTransportOrder?.name;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'consignment':
        return (
          <ConsignmentInput
            {...this.props}
            consignment={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newConsignment) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newConsignment?.id;
              newTodoForm.relatedEntityName = newConsignment?.name;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'action':
      case 'loadAction':
      case 'stopAction':
      case 'unloadAction':
        return (
          <ActionInput
            {...this.props}
            action={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            actionType={this.getActionType(todoForm?.relatedEntityType)}
            onChange={(newAction) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newAction?.id;
              newTodoForm.relatedEntityName = newAction?.name || newAction?.id;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'trip':
        return (
          <TripInput
            {...this.props}
            trip={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newTrip) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newTrip?.id;
              newTodoForm.relatedEntityName = newTrip?.name;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'chauffeur':
        return (
          <ChauffeurInput
            {...this.props}
            vehicle={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newChauffeur) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newChauffeur?.id;
              newTodoForm.relatedEntityName = newChauffeur?.firstName + newChauffeur?.lastName;
              changeValue('todoForm', newTodoForm);
            }}
          />
        );
      case 'transportEquipment':
        return (
          <TransportEquipmentInput
            {...this.props}
            vehicle={
              relatedEntity || { id: todoForm?.relatedEntityId, name: todoForm?.relatedEntityName }
            }
            onChange={(newTransportEquipment) => {
              const { changeValue, todoForm } = this.props;
              const newTodoForm = { ...todoForm };
              newTodoForm.relatedEntityId = newTransportEquipment?.id;
              newTodoForm.relatedEntityName = newTransportEquipment?.name;

              changeValue('todoForm', newTodoForm);
            }}
          />
        );
    }
  };

  render() {
    const { editorState } = this.state;
    const { t, todoForm, isEdit, board, relatedEntity } = this.props;

    const entityTypes = [
      { label: 'Transport Order', value: 'transportOrder' },
      { label: 'Consignment', value: 'consignment' },
      // { label: 'Action', value: 'action' },
      // { label: 'Load Action', value: 'loadAction' },
      // { label: 'Unload Action', value: 'unloadAction' },
      // { label: 'Stop Action', value: 'stopAction' },
      { label: 'Trip', value: 'trip' },
      { label: 'Vehicle', value: 'vehicle' },
      { label: 'Driver', value: 'chauffeur' },
      { label: 'Transport Equipment', value: 'transportEquipment' },
    ];

    return (
      <div className="todo-form">
        <h1 className="d-flex justify-content-center">
          {isEdit ? t('todo.form.editTodo') : t('todo.form.createTodo')}
        </h1>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                required
                type="text"
                label="form.label.title"
                name="name"
                maxLength={40}
                value={todoForm?.name}
                onChange={(e) => {
                  const { changeValue, todoForm } = this.props;
                  const newTodoForm = { ...todoForm };
                  newTodoForm.name = e.target.value;
                  changeValue('todoForm', newTodoForm);
                }}
              />
              {this.validator.message(t('form.label.title'), todoForm?.name, 'required|max:40')}
            </div>

            <div className="input-group no-margin-top">
              <label className="font-sm">{t('todo.form.label.description')}</label>
              <Editor
                editorState={editorState}
                toolbarClassName="actions-bar"
                editorClassName="message-input"
                wrapperClassName="editor-wrapper"
                onEditorStateChange={this.onEditorStateChange}
                onChange={() => {
                  this.onChangeHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())));
                }}
                toolbar={{
                  options: ['inline', 'textAlign', 'list', 'colorPicker'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                    className: 'actions',
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['unordered', 'ordered'],
                  },
                  textAlign: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['center', 'right', 'justify'],
                  },
                }}
              />
            </div>

            <div className="input-group no-margin-top">
              <label>{t('todo.dueDate')}*</label>
              <div className="input-group mt-30">
                <input
                  required
                  type="datetime-local"
                  onChange={(e) => {
                    const { changeValue, todoForm } = this.props;
                    const newTodoForm = { ...todoForm };
                    newTodoForm.deadline = moment(e.target.value);
                    changeValue('todoForm', newTodoForm);
                  }}
                  value={moment(todoForm?.deadline).format('YYYY-MM-DDTHH:mm')}
                />
              </div>
              {this.validator.message(t('todo.dueDate'), todoForm?.deadline, 'required')}
            </div>

            <div className="input-group no-margin-top">
              <label>{t('form.label.documents')}</label>
              <div className="mt-20">
                <Attachments
                  {...this.props}
                  key={todoForm?.documents}
                  files={todoForm?.documents?.map((document) => {
                    return document.entity.file;
                  })}
                  onChange={(files) => {
                    const { changeValue, todoForm } = this.props;

                    const newTodoForm = { ...todoForm };
                    newTodoForm.documents = files.map((file) => {
                      const newFile = { ...file };
                      return new Association('inline', {
                        name: newFile.originalName,
                        mimeType: newFile.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: newFile.url,
                        },
                        file: newFile,
                      });
                    });
                    changeValue('todoForm', newTodoForm);
                  }}
                />
              </div>
            </div>

            <div className="input-group no-margin-top">
              <label>{t('todo.form.label.boardName')}*</label>
              <div className="mt-30">
                <BoardInput
                  {...this.props}
                  board={board ? (relatedEntity ? null : board) : todoForm?.lane?.board}
                  placeholder={t('form.placeholder.boardName')}
                  onChange={(newBoard) => {
                    const { changeValue, todoForm } = this.props;
                    const newTodoForm = { ...todoForm };
                    newTodoForm.lane = newBoard?.lanes
                      .sort((a, b) => b.position - a.position)
                      .pop();
                    changeValue('todoForm', newTodoForm);
                  }}
                />
              </div>
              {this.validator.message(
                t('todo.form.label.boardName'),
                todoForm?.lane || board,
                'required'
              )}
            </div>

            <div className="input-group no-margin-top">
              <label>{t('form.label.relation')}</label>
              <div className="mt-30">
                <Select
                  options={entityTypes}
                  placeholder={t('form.placeholder.relation')}
                  value={entityTypes.filter((type) => type.value === todoForm?.relatedEntityType)}
                  onChange={(e) => {
                    const { changeValue, todoForm } = this.props;
                    const newTodoForm = { ...todoForm };
                    newTodoForm.relatedEntityType = e.value;
                    changeValue('todoForm', newTodoForm);
                  }}
                />
              </div>
            </div>

            {todoForm?.relatedEntityType && (
              <>
                <div className="input-group no-margin-top">
                  <label>{`${t('select')} ${t(todoForm?.relatedEntityType)}`}*</label>
                  <div className="mt-30">{this.relatedEntityInput()}</div>
                </div>
                {this.validator.message(
                  t('form.label.relation'),
                  todoForm?.relatedEntityName || relatedEntity?.id,
                  'required'
                )}
              </>
            )}

            <div className="input-group no-margin-top">
              <label>{t('form.label.assignee')}*</label>
              <div className="mt-30">
                <Select
                  options={this.todoAssignees()}
                  placeholder={t('form.placeholder.assignee')}
                  value={this.todoAssignees().find((user) => user.id === todoForm?.assignee?.id)}
                  onChange={(e) => {
                    const { changeValue, todoForm } = this.props;
                    const newTodoForm = { ...todoForm };
                    newTodoForm.assignee = this.props.users
                      .filter((user) => user.id === e.value)
                      .pop();
                    changeValue('todoForm', newTodoForm);
                  }}
                />
              </div>
              {this.validator.message(t('form.label.assignee'), todoForm?.assignee, 'required')}
            </div>

            <div className="input-group more right">
              <button
                onClick={(e) => (isEdit ? this.updateTodo(e) : this.createTodo(e))}
                disabled={isEdit ? false : !this.validator.allValid()}
              >
                {isEdit ? t('form.update') : t('form.save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
