import * as messageAutomationApi from '@api/messageAutomationApi';

import { messageAutomationConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: messageAutomationConstants.MESSAGE_AUTOMATION_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching Message Automation
 */
export const startGetMessageAutomation = () => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_STARTED,
});

export const getMessageAutomationSuccess = (result) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_SUCCESS,
  ...result,
});

export const getMessageAutomationFailure = (error) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_FAILURE,
  ...error,
});

export const getMessageAutomation = (id) => {
  return (dispatch) => {
    dispatch(startGetMessageAutomation());

    const promise = messageAutomationApi.get(id);

    promise
      .then((result) => {
        dispatch(getMessageAutomationSuccess(result));
      })
      .catch((error) => {
        dispatch(getMessageAutomationFailure(error));
      });

    return promise;
  };
};

/**
 * Creating Message Automation
 */
export const changeCreateMessageAutomation = (name, value) => ({
  type: messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_CHANGE_VALUE,
  name,
  value,
});
export const startCreateMessageAutomation = () => ({
  type: messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_STARTED,
});

export const createMessageAutomationSuccess = (result) => ({
  type: result?.messageAutomation?.template
    ? messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS
    : messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_SUCCESS,
  ...result,
});

export const createMessageAutomationFailure = (error) => ({
  type: messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_FAILURE,
  ...error,
});

export const createMessageAutomation = (messageAutomation) => {
  return (dispatch) => {
    dispatch(startCreateMessageAutomation());

    const promise = messageAutomationApi.create(messageAutomation);

    promise
      .then((result) => {
        dispatch(createMessageAutomationSuccess(result));
      })
      .catch((error) => {
        dispatch(createMessageAutomationFailure(error));
      });

    return promise;
  };
};

/**
 * Updating Message Automation
 */
export const startUpdateMessageAutomation = () => ({
  type: messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_STARTED,
});

export const updateMessageAutomationSuccess = (result) => ({
  type: result?.messageAutomation?.template
    ? messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS
    : messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_SUCCESS,
  ...result,
});

export const updateMessageAutomationFailure = (error) => ({
  type: messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_FAILURE,
  ...error,
});

export const updateMessageAutomation = (messageAutomation) => {
  return (dispatch) => {
    dispatch(startUpdateMessageAutomation());

    const promise = messageAutomationApi.update(messageAutomation);

    promise
      .then((result) => {
        dispatch(updateMessageAutomationSuccess(result));
      })
      .catch((error) => {
        dispatch(updateMessageAutomationFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting Message Automation
 */
export const startDeleteMessageAutomation = () => ({
  type: messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_STARTED,
});

export const deleteMessageAutomationSuccess = (result) => ({
  type: messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_SUCCESS,
  ...result,
});

export const deleteMessageAutomationFailure = (error) => ({
  type: messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_FAILURE,
  ...error,
});
export const deleteMessageAutomation = (messageAutomation) => {
  return (dispatch) => {
    dispatch(startDeleteMessageAutomation());

    const promise = messageAutomationApi.remove(messageAutomation);

    promise
      .then((result) => {
        dispatch(deleteMessageAutomationSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteMessageAutomationFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching Message Automation
 */
export const startGetMessageAutomations = (from) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_STARTED,
  from: from,
});

export const getMessageAutomationsSuccess = (result, from) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_SUCCESS,
  from: from,
  ...result,
});

export const getMessageAutomationsFailure = (error) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_FAILURE,
  ...error,
});

export const getMessageAutomations = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetMessageAutomations(from));

    const promise = messageAutomationApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getMessageAutomationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getMessageAutomationsFailure(error));
      });

    return promise;
  };
};

export const startGetMessageAutomationTemplates = (from) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_STARTED,
  from: from,
});

export const getMessageAutomationTemplatesSuccess = (result) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_SUCCESS,
  ...result,
});

export const getMessageAutomationTemplatesFailure = (error) => ({
  type: messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_FAILURE,
  ...error,
});

export const getMessageAutomationTemplates = () => {
  return (dispatch) => {
    dispatch(startGetMessageAutomationTemplates());

    const promise = messageAutomationApi.getMessageAutomationTemplates();

    promise
      .then((result) => {
        dispatch(getMessageAutomationTemplatesSuccess(result));
      })
      .catch((error) => {
        dispatch(getMessageAutomationTemplatesFailure(error));
      });

    return promise;
  };
};

export const searchMessageAutomations = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetMessageAutomations(from));

    const promise = messageAutomationApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getMessageAutomationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getMessageAutomationsFailure(error));
      });

    return promise;
  };
};
