import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';

class HeadersInput extends Component {
  state = {
    headers: this.props.headers
      ? Object.keys(this.props.headers).map((headerKey) => ({
          key: headerKey,
          value: this.props.headers[headerKey],
        }))
      : this.props.defaultEnabled
      ? [{}]
      : [],
  };

  onChange = (headers) => {
    const { onChange } = this.props;
    onChange &&
      onChange(
        headers.reduce((result, item) => {
          result[item.key] = item.value;
          return result;
        }, {})
      );
  };

  addNewHeader = () => {
    const { headers } = this.state;
    this.setState({
      headers: [...headers, {}],
    });
  };

  updateHeader = (newHeader, index) => {
    const { headers } = this.state;
    const newHeaders = [...headers];

    newHeaders[index] = newHeader;

    this.setState({
      headers: newHeaders,
    });

    this.onChange(newHeaders);
  };

  render() {
    const { t } = this.props;
    const { headers } = this.state;

    return (
      <>
        {headers.map((header, index) => (
          <div key={`input-header-${index}`}>
            <div className="list-actions">
              <div></div>
              <CloseButton
                onClick={() => {
                  const newHeaders = [...headers];
                  newHeaders.splice(index, 1);

                  this.setState({
                    headers: newHeaders,
                  });
                  this.onChange(newHeaders);
                }}
              />
            </div>

            <div className="input-group no-margin-top">
              <div className="input-group third no-margin-top">
                <FormInput
                  type="text"
                  label="form.label.key"
                  wrapperClass="no-margin-top"
                  value={header.key}
                  onChange={(event) => {
                    const newHeader = { ...header };
                    newHeader.key = event.target.value;

                    this.updateHeader(newHeader, index);
                  }}
                />
              </div>
              <div className="input-group twothird no-margin-top">
                <FormInput
                  type="text"
                  value={header.value}
                  label="form.label.value"
                  wrapperClass="no-margin-top"
                  onChange={(event) => {
                    const newHeader = { ...header };
                    newHeader.value = event.target.value;

                    this.updateHeader(newHeader, index);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        <div className={`input-group left${headers.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewHeader(e)}>
            {t('form.label.addHeader')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(HeadersInput);
