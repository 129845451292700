import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class MessageAutomationTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = [
      {
        value: 'messageAutomation',
        label: t('messageAutomationType.messageMessageAutomation'),
      },
      {
        value: 'questionAnswerAutomation',
        label: t('messageAutomationType.questionAnswerMessageAutomation'),
      },
      {
        value: 'fileRequestAutomation',
        label: t('messageAutomationType.fileRequestMessageAutomation'),
      },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(MessageAutomationTypeSelector);
