import { messageAutomationConstants } from '@constants/messageAutomation.constants';

const initialState = {
  isFetchingMessageAutomation: false,
  formMessageAutomation: null,
  messageAutomation: null,

  isFetchingMessageAutomations: false,
  isFetchingMessageAutomationsFrom: 0,
  messageAutomationsTotalResults: 0,
  messageAutomationsLoaded: 0,
  messageAutomations: [],

  isFetchingMessageAutomationTemplates: false,
  messageAutomationTemplatesTotalResults: 0,
  messageAutomationTemplatesLoaded: 0,
  messageAutomationTemplates: [],
};

export default function messageAutomation(state = initialState, action) {
  switch (action.type) {
    case messageAutomationConstants.MESSAGE_AUTOMATION_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_STARTED:
      return { ...state, isFetchingMessageAutomation: true };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        error: action.message || '',
      };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
      };

    case messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_STARTED:
      return {
        ...state,
        isFetchingMessageAutomations: true,
        isFetchingMessageAutomationsFrom: action.from || 0,
      };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomations: false,
        error: action.message || '',
      };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATIONS_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomations: false,
        messageAutomations:
          action.from > 0
            ? [...state.messageAutomations, ...action.messageAutomations]
            : action.messageAutomations,
        messageAutomationsLoaded:
          action.from > 0
            ? state.messageAutomations.length + action.messageAutomations.length
            : action.messageAutomations.length,
        messageAutomationsTotalResults: action.totalResults || 0,
      };

    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_STARTED:
      return {
        ...state,
        isFetchingMessageAutomationTemplates: true,
      };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomationTemplates: false,
        error: action.message || '',
      };
    case messageAutomationConstants.GET_MESSAGE_AUTOMATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomationTemplates: false,
        messageAutomationTemplates: action.messageAutomations,
        messageAutomationTemplatesLoaded: action.messageAutomations.length,
        messageAutomationTemplatesTotalResults: action.totalResults || 0,
      };

    case messageAutomationConstants.SEARCH_MESSAGE_AUTOMATIONS_STARTED:
      return {
        ...state,
        isFetchingMessageAutomations: true,
        isFetchingMessageAutomationsFrom: action.from || 0,
      };
    case messageAutomationConstants.SEARCH_MESSAGE_AUTOMATIONS_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomations: false,
        error: action.message || '',
      };
    case messageAutomationConstants.SEARCH_MESSAGE_AUTOMATIONS_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomations: false,
        messageAutomations:
          action.from > 0
            ? [...state.messageAutomations, ...action.messageAutomations]
            : action.messageAutomations,
        messageAutomationsLoaded:
          action.from > 0
            ? state.messageAutomations.length + action.messageAutomations.length
            : action.messageAutomations.length,
        messageAutomationsTotalResults: action.totalResults || 0,
      };

    case messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_STARTED:
      return { ...state, isFetchingMessageAutomation: true };
    case messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        error: action.message || '',
      };
    case messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
        messageAutomations: [action.messageAutomation, ...state.messageAutomations],
      };

    case messageAutomationConstants.CREATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
        messageAutomationTemplates: [action.messageAutomation, ...state.messageAutomationTemplates],
      };

    case messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_STARTED:
      return { ...state, isFetchingMessageAutomation: true };
    case messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        error: action.message || '',
      };
    case messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
        messageAutomations: [...state.messageAutomations].map((messageAutomation) => {
          if (action.messageAutomation.id === messageAutomation.id) {
            return action.messageAutomation;
          }
          return messageAutomation;
        }),
      };

    case messageAutomationConstants.UPDATING_MESSAGE_AUTOMATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
        messageAutomationTemplates: [...state.messageAutomationTemplates].map(
          (messageAutomation) => {
            if (action.messageAutomation.id === messageAutomation.id) {
              return action.messageAutomation;
            }
            return messageAutomation;
          }
        ),
      };

    case messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_STARTED:
      return { ...state, isFetchingMessageAutomation: true };
    case messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_FAILURE:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        message: action.message || '',
      };
    case messageAutomationConstants.DELETE_MESSAGE_AUTOMATION_SUCCESS:
      return {
        ...state,
        isFetchingMessageAutomation: false,
        messageAutomation: action.messageAutomation,
        messageAutomations: [...state.messageAutomations].filter(
          (messageAutomation) => action.messageAutomation.id !== messageAutomation.id
        ),
      };
    default:
      return state;
  }
}
