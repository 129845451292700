import axios from 'axios';
import Promise from 'promise';

import * as settingsActions from '@actions/settingsActions';

import { handleError, handleResponse } from '@utils/responseMiddleware';

import { store } from './index';

let activeRequests = {};

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  },
};
const instance = axios.create(defaultOptions);

instance.interceptors.request.use(
  (config) => {
    const storeState = store.getState();
    const token = storeState.auth.bearer;

    config.headers.Authorization = `Bearer ${token}`;
    config.headers['X-Referer'] = window.location.hostname;
    config.headers['X-Window-Id'] = window.name;
    config.headers['Accept-RequestLanguage'] =
      window.navigator.userLanguage || window.navigator.language || 'en';

    const platform = storeState.platform.platform;
    if (platform && platform !== undefined && platform !== null) {
      config.headers.Platform = platform ? platform.id : '';
    }

    const widget = storeState?.widget?.widget;
    if (widget && storeState?.widget?.hasPublicWidgetLoaded) {
      // config.headers.Widget = widget ? widget.id : '';
      config.headers['x-api-key'] = widget.apiAccessIntegration?.accessKey;
      config.headers['x-api-secret'] = widget.apiAccessIntegration?.secretKey;
    }

    if (activeRequests[cleanURL(config.url)]) {
      const cancelController = activeRequests[cleanURL(config.url)];
      cancelController.abort();
      delete activeRequests[cleanURL(config.url)];
    }

    const controller = new AbortController();
    config.signal = controller.signal;
    activeRequests[cleanURL(config.url)] = controller;

    return config;
  },
  (error) => {
    delete activeRequests[cleanURL(error.config.url)];
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    delete activeRequests[cleanURL(response.config.url)];
    const latestAppVersion = response?.headers?.['x-version'];
    const currentAppVersion = localStorage.getItem('x-version');
    if (currentAppVersion) {
      if (latestAppVersion > currentAppVersion) {
        const transportialData = localStorage.getItem('storage');
        localStorage.clear();

        localStorage.setItem('version-update-needed', true);
        localStorage.setItem('x-version', latestAppVersion);
        localStorage.setItem('transportial', transportialData);
      }
    } else {
      localStorage.setItem('x-version', latestAppVersion);
    }
    if (response?.data?.success === true) {
      return Promise.resolve(handleResponse(response));
    } else {
      return Promise.reject(handleError(response));
    }
  },
  (error) => {
    if (error.code === 'ERR_NETWORK') {
    }
    if (error.config) {
      if (error?.response?.data?._entity === 'NeedsBillingApprovalResponse') {
        const errorMessage =
          '€' + error.response.data.pricePerUnit.amount + ' per ' + error.config.url.split('/')[1];
        const newConfig = { ...error.config };
        const configData = { ...JSON.parse(error.config.data), hasAgreedToPrice: true };
        newConfig.data = configData;
        store.dispatch(settingsActions.setPaymentRetryConfig(newConfig));
        store.dispatch(settingsActions.setTutorialErrorMessage(errorMessage));
        store.dispatch(settingsActions.setTutorial('payment'));
      }
    }
    delete activeRequests[cleanURL(error.config.url)];
    return Promise.reject(handleError(error));
  }
);

const cleanURL = (url) => {
  return url;
};
export default instance;
