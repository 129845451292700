import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/messageAutomation/${id}`);
};

export const create = (messageAutomation) => {
  return api.post(`/messageAutomation`, messageAutomation);
};

export const update = (messageAutomation) => {
  return api.put(`/messageAutomation`, messageAutomation);
};

export const remove = (messageAutomation) => {
  return api.delete(`/messageAutomation/${messageAutomation.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/messageAutomations/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/messageAutomations/${offset}/${amount}`, searchParameters);
};

export const getMessageAutomationTemplates = () => {
  return api.get(`/messageAutomation-templates`);
};
