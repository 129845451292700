import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FormInput from '@uiinputs/FormInput';

class FlatIdentifierInput extends Component {
  render() {
    const { entityType, entity, onChange, integrationRequiredIdentifiersPerEntity } = this.props;
    const fields = integrationRequiredIdentifiersPerEntity[entityType || ''] || [];
    return (
      <>
        {fields.map((field) => (
          <FormInput
            type="text"
            value={entity.identifiers?.find((identifier) => identifier.name === field)?.value}
            label={field}
            onChange={(e) => {
              const newIdentifiers = [...entity?.identifiers];
              var newIdentifierIndex = newIdentifiers.findIndex(
                (identifier) => identifier.name == field
              );
              if (newIdentifierIndex < 0) newIdentifierIndex = 0;

              const newIdentifier = {
                ...newIdentifiers[newIdentifierIndex],
              };

              newIdentifier.name = field;
              newIdentifier.value = e.target.value;

              newIdentifiers[newIdentifierIndex] = newIdentifier;

              onChange(newIdentifiers);
            }}
          />
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    integrationRequiredIdentifiersPerEntity: state.platform.integrationRequiredIdentifiersPerEntity,
  };
};

export default connect(mapStateToProps)(withTranslation('translation')(FlatIdentifierInput));
