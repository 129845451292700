import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import FormInput from '@uiinputs/FormInput';
import VariableContentEditable from '@uiinputs/VariableContentEditable';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class MessageTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, formMessageTemplate, setHasChanged } = this.props;
    onSubmit && onSubmit(formMessageTemplate);
    setHasChanged(false);
  }

  render() {
    const {
      t,
      onChange,
      languages,
      formMessageTemplate,
      fields,
      searchFieldsRequest,
      setHasChanged,
    } = this.props;
    const languageOptions = languages.map((language) => {
      return { label: language.localName, value: language.id, language };
    });

    const entityTypeOptions = [
      { label: t('entityType.vehicle'), value: 'vehicle' },
      { label: t('entityType.trip'), value: 'trip' },
    ];

    return (
      <div className="step-form" key={formMessageTemplate.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.name"
                required={true}
                value={formMessageTemplate.name}
                onChange={(event) => {
                  const newMessageTemplate = { ...formMessageTemplate };
                  newMessageTemplate.name = event.target.value;

                  onChange('formMessageTemplate', newMessageTemplate, event);
                }}
              />
              {this.validator.message(t('form.label.name'), formMessageTemplate.name, 'required')}
            </div>
            <div className="input-group">
              <Select
                placeholder={t('form.label.selectLanguage')}
                options={languageOptions}
                value={languageOptions.find(
                  (languageOption) =>
                    languageOption.value ===
                    (formMessageTemplate.language ? formMessageTemplate.language.id : null)
                )}
                noOptionsMessage={() => t('noOptions')}
                onChange={(e) => {
                  const newMessageTemplate = { ...formMessageTemplate };

                  newMessageTemplate.language = e.language;

                  onChange('formMessageTemplate', newMessageTemplate);
                }}
              />
            </div>
            <div className="input-group">
              <Select
                placeholder={`${t('form.label.selectEntityContext')}*`}
                options={entityTypeOptions}
                value={entityTypeOptions.find(
                  (entityTypeOption) => entityTypeOption.value === formMessageTemplate.contextEntity
                )}
                noOptionsMessage={() => t('noOptions')}
                onChange={(e) => {
                  const newMessageTemplate = { ...formMessageTemplate };
                  newMessageTemplate.contextEntity = e.value;
                  onChange('formMessageTemplate', newMessageTemplate);
                }}
              />
            </div>
            <div className="input-group">
              <b>{t('form.label.messageTemplate')}</b>
              <div className="input-group no-margin-top">
                <VariableContentEditable
                  value={formMessageTemplate.message}
                  fields={fields}
                  formMessageTemplate={formMessageTemplate}
                  searchFieldsRequest={searchFieldsRequest}
                  onChange={(newValue) => {
                    const newMessageTemplate = { ...formMessageTemplate };
                    newMessageTemplate.message = newValue;

                    onChange('formMessageTemplate', newMessageTemplate, event);
                  }}
                />
              </div>
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
