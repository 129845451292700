import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import SelectorTypeSelector from '../crud/SelectorTypeSelector';
import ValueTypeSelector from '../crud/ValueTypeSelector';
import FieldMappingsInput from '../dataSource/FieldMappingsInput';
import IntegrationFileFieldSelectorsInput from './IntegrationFileFieldSelectorsInput';

class IntegrationFileFieldSelectorInput extends Component {
  state = this.props.value
    ? this.props.value
    : {
        selectorType: null,
        selector: '',
        fieldType: null,
        regex: '',
        format: '',
        path: '',

        toTop: 0,
        toRight: 0,
        toBottom: 0,
        toLeft: 0,

        fields: [],
        options,
      };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { t, onDelete, onChange } = this.props;
    const {
      selectorType,
      selector,
      fieldType,
      regex,

      format,
      locale,
      zoneId,
      top,
      right,
      bottom,
      left,

      insertRegexSelector,
      insertType,
      insertValue,

      fields,
      path,
      options,
    } = this.state;
    const fieldOptionOptions = [
      { value: 'identifier', label: t('fieldOption.identifier') },
      { value: 'to_lower_case', label: t('fieldOption.to_lower_case') },
      { value: 'to_upper_case', label: t('fieldOption.to_upper_case') },
      { value: 'capitalize', label: t('fieldOption.capitalize') },
      { value: 'trim', label: t('fieldOption.trim') },
      {
        value: 'remove_all_whitespace',
        label: t('fieldOption.remove_all_whitespace'),
      },
    ];

    const fieldInsertOptions = [
      { value: 'before', label: 'before' },
      { value: 'replace', label: 'replace' },
      { value: 'after', label: 'after' },
    ];
    return (
      <>
        <div className="input-group flex-container no-wrap">
          <div className="input-group tenth no-margin-top">
            <SelectorTypeSelector
              value={selectorType}
              placeholder={t('form.label.selectSelectorType')}
              onChange={(e) => {
                this.setState(
                  {
                    selectorType: e.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              wrapperClass="no-margin-top"
              label="form.label.selector"
              value={selector}
              onChange={(e) => {
                this.setState(
                  {
                    selector: e.target.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fifth no-margin-top">
            <ValueTypeSelector
              value={fieldType}
              placeholder={t('form.label.selectValueType')}
              onChange={(e) => {
                this.setState(
                  {
                    fieldType: e.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              wrapperClass="no-margin-top"
              label="form.label.jsonPath"
              value={path}
              onChange={(e) => {
                this.setState(
                  {
                    path: e.target.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              wrapperClass="no-margin-top"
              label="form.label.regex"
              value={regex}
              onChange={(e) => {
                this.setState(
                  {
                    regex: e.target.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          {(fieldType === 'datetime' || fieldType === 'date' || fieldType === 'time') && (
            <>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  wrapperClass="no-margin-top"
                  label="form.label.format"
                  value={format}
                  onChange={(e) => {
                    this.setState(
                      {
                        format: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  wrapperClass="no-margin-top"
                  label="form.label.locale"
                  value={locale}
                  onChange={(e) => {
                    this.setState(
                      {
                        locale: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="text"
                  wrapperClass="no-margin-top"
                  label="form.label.zoneId"
                  value={zoneId}
                  onChange={(e) => {
                    this.setState(
                      {
                        zoneId: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
            </>
          )}
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              wrapperClass="no-margin-top"
              label="form.label.insertRegexSelector"
              value={insertRegexSelector}
              onChange={(e) => {
                this.setState(
                  {
                    insertRegexSelector: e.target.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <Select
              value={fieldInsertOptions.filter((option) => insertType === option.value)}
              options={fieldInsertOptions}
              placeholder={t('form.label.insertType')}
              onChange={(e) => {
                this.setState(
                  {
                    insertType: e.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fourth no-margin-top">
            <FormInput
              type="text"
              wrapperClass="no-margin-top"
              label="form.label.insertValue"
              value={insertValue}
              onChange={(e) => {
                this.setState(
                  {
                    insertValue: e.target.value,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          {selectorType === 'text_variable_position' && (
            <>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="number"
                  wrapperClass="no-margin-top"
                  label="form.label.top"
                  value={top}
                  onChange={(e) => {
                    this.setState(
                      {
                        top: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="number"
                  wrapperClass="no-margin-top"
                  label="form.label.right"
                  value={right}
                  onChange={(e) => {
                    this.setState(
                      {
                        right: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  type="number"
                  wrapperClass="no-margin-top"
                  label="form.label.bottom"
                  value={bottom}
                  onChange={(e) => {
                    this.setState(
                      {
                        bottom: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group fourth no-margin-top">
                <FormInput
                  wrapperClass="no-margin-top"
                  type="number"
                  label="form.label.left"
                  value={left}
                  onChange={(e) => {
                    this.setState(
                      {
                        left: e.target.value,
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
            </>
          )}
          <div className="input-group fourth no-margin-top">
            <Select
              isMulti={true}
              value={fieldOptionOptions.filter((option) => options?.includes(option.value))}
              options={fieldOptionOptions}
              onChange={(e) => {
                this.setState(
                  {
                    options: e ? e?.map((foo) => foo.value) : [],
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
          <div className="input-group fifth no-margin-top">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onDelete(e);
              }}
            >
              {t('form.delete')}
            </button>
          </div>
        </div>
        {(fieldType === 'array' || fieldType === 'object' || fieldType === 'filters') && (
          <div className="list-sector">
            {fieldType === 'filters' && (
              <IntegrationFiltersInput
                value={filters ? filters : []}
                onChange={(newFilters) => {
                  this.setState(
                    {
                      filters: newFilters,
                    },
                    () => {
                      onChange && onChange(this.state);
                    }
                  );
                }}
              />
            )}

            <IntegrationFileFieldSelectorsInput
              key={`field-${value.sequenceNr}-fields`}
              value={fields}
              onChange={(newFieldSelectors) => {
                this.setState(
                  {
                    fields: newFieldSelector,
                  },
                  () => {
                    onChange && onChange(this.state);
                  }
                );
              }}
            />
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(IntegrationFileFieldSelectorInput);
