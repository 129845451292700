import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTransportEquipmentPage from '@components/goods/crud/CreateTransportEquipmentPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as transportEquipmentActions from '@actions/transportEquipmentActions';

class CreateTransportEquipmentContainer extends PureComponent {
  render() {
    const { changeValue, createTransportEquipment, popStack, ...props } = this.props;
    return (
      <CreateTransportEquipmentPage
        {...props}
        onChange={changeValue}
        createTransportEquipment={(transportEquipment) => {
          createTransportEquipment(transportEquipment).then(() => {
            popStack();
          });
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,

    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,
    types: state.platform.types,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.transportEquipment,
    ...state.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTransportEquipment: (transportEquipment) =>
      dispatch(transportEquipmentActions.createTransportEquipment(transportEquipment)),
    changeValue: (name, value) => dispatch(transportEquipmentActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTransportEquipmentContainer));
