import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTransportOrderPage from '@components/transportOrder/crud/CreateTransportOrderPage.js';

import * as fileApi from '@api/fileApi';

import * as administrationActions from '@actions/administrationActions';
import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';
import * as transportOrderActions from '@actions/transportOrderActions';

class CreateTransportOrderContainer extends PureComponent {
  render() {
    const {
      callback,
      changeValue,
      changeQuoteValue,
      searchBusinesses,
      createTransportOrder,
      ...props
    } = this.props;
    const { popStack } = this.props;

    return (
      <CreateTransportOrderPage
        {...props}
        onChange={changeValue}
        changeValue={changeQuoteValue}
        createTransportOrder={(transportOrder) =>
          createTransportOrder(transportOrder)
            .then((response) => {
              popStack?.();
              callback?.(response.transportOrder);
            })
            .catch((error) => {
              console.error(error);
              popStack?.();
            })
        }
        searchBusinesses={(searchParameters, from, amount) =>
          searchBusinesses(searchParameters, from, amount)
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    simpleForms: state.settings?.settings?.userInterface?.simpleForms,
    userInterfaceSettings: state.settings?.settings?.userInterface,

    user: state.auth.user,
    currencies: state.platform.currencies,
    isFetchingCurrencies: state.platform.isFetchingCurrencies,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    variables: state.settings.variables,
    ...state.transportOrder,
    ...state.administration,
    ...state.business,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeQuoteValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    createTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.createTransportOrder(transportOrder)),
    changeValue: (name, value) => dispatch(transportOrderActions.changeValue(name, value)),

    searchBusinesses: (searchParameters, from, amount) =>
      dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTransportOrderContainer));
