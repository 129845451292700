import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TodoDetails from '@components/todo/todo/TodoDetails';

import * as boardActions from '@actions/boardActions';
import * as commentActions from '@actions/commentActions';
import * as navigationActions from '@actions/navigationActions';
import * as todoActions from '@actions/todoActions';
import * as todoMainActions from '@actions/todoMainActions';

class TodoDetailsContainer extends Component {
  render() {
    return <TodoDetails {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || "DD/MM/YYYY",
    ...state.todo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isEdit: (payload) => dispatch(todoMainActions.isEdit(payload)),
    setView: (payload) => dispatch(todoMainActions.setView(payload)),
    changeValue: (name, value) => dispatch(todoMainActions.changeValue(name, value)),

    getTodo: (todo) => dispatch(todoActions.getTodo(todo)),
    deleteTodo: (todo) => dispatch(todoActions.deleteTodo(todo)),

    getBoardById: (id) => dispatch(boardActions.getBoardById(id)),

    deleteComment: (comment) => dispatch(commentActions.deleteComment(comment)),
    getComments: (todo, from, amount) => dispatch(commentActions.getComments(todo, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TodoDetailsContainer));
