import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import CloseButton from '@uicomponents/CloseButton';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import SelectFieldpathInput from '@uiinputs/SelectFieldpathInput';

import Filter from '@models/integration/Filter';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import ComparisonLinkTypeSelector from './ComparisonLinkTypeSelector';
import ComparisonTypeSelector from './ComparisonTypeSelector';

class IntegrationFiltersInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: this.props.value
        ? [...this.props.value].sort((a, b) =>
            moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
          )
        : this.props.defaultEnabled
        ? [new Filter()]
        : [],
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newFilters) => {
    const { onChange } = this.props;

    this.setState({
      filters: newFilters,
    });

    onChange && onChange(newFilters);
  };

  addFilter = () => {
    const { filters } = this.state;
    this.setState({
      filters: [...filters, new Filter()],
    });
  };

  render() {
    const { t, key, defaultEnabled, superIndex, entityType } = this.props;
    const { filters } = this.state;

    const nextSuperIndex = (superIndex || 0) + 1;
    return (
      <>
        {filters.map((filter, index) => (
          <div className="list-sector" key={`${key}-filter-${index}`}>
            {((defaultEnabled && index !== 0) || !defaultEnabled) && (
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newFilters = [...filters];
                    newFilters.splice(index, 1);

                    this.onChange(newFilters);
                  }}
                />
              </div>
            )}
            {(index > 0 || superIndex > 0) && (
              <div className="input-group no-margin-top">
                <div className="input-group">
                  <ComparisonLinkTypeSelector
                    key={filter.linkType}
                    value={filter.linkType}
                    placeholder={t('form.label.selectComparisonLinkType')}
                    onChange={(e) => {
                      const newFilters = [...filters];
                      const newFilter = { ...filter };
                      newFilter.linkType = e.value;

                      newFilters[index] = newFilter;

                      this.onChange(newFilters);
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className={`selector input-group${
                index > 0 || superIndex > 0 ? '' : ' no-margin-top'
              }`}
            >
              <SelectFieldpathInput
                type="text"
                value={filter.selector}
                label="form.label.selector"
                entityType={entityType}
                onChange={(value) => {
                  const newFilters = [...filters];
                  const newFilter = { ...filter };
                  newFilter.selector = value;

                  newFilters[index] = newFilter;

                  this.onChange(newFilters);
                }}
              />
            </div>
            <div className="input-group no-margin-top comparison">
              <div className="input-group">
                <ComparisonTypeSelector
                  key={filter.comparisonType}
                  value={filter.comparisonType}
                  placeholder={t('form.label.selectComparisonType')}
                  onChange={(e) => {
                    const newFilters = [...filters];
                    const newFilter = { ...filter };
                    newFilter.comparisonType = e.value;

                    newFilters[index] = newFilter;

                    this.onChange(newFilters);
                  }}
                />
              </div>
            </div>
            {!filter.comparisonType?.includes('is') && (
              <div className="input-group value">
                <FormInput
                  type="text"
                  wrapperClass="no-margin-top"
                  label="form.label.value"
                  value={filter.value}
                  onChange={(e) => {
                    const newFilters = [...filters];
                    const newFilter = { ...filter };
                    newFilter.value = e.target.value;

                    newFilters[index] = newFilter;

                    this.onChange(newFilters);
                  }}
                />
              </div>
            )}

            <div className="input-group">
              <IntegrationFiltersInput
                t={t}
                superIndex={nextSuperIndex}
                entityType={entityType}
                value={filter.filters}
                onChange={(filtersInput) => {
                  const newFilters = [...filters];
                  const newFilter = { ...filter };
                  newFilter.filters = filtersInput;

                  newFilters[index] = newFilter;

                  this.onChange(newFilters);
                }}
              />
            </div>
          </div>
        ))}
        <div className={`input-group left${filters.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addFilter(e)}>
            {t('form.label.addFilter')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(IntegrationFiltersInput);
