import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

export default class AttributeForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  onChange = (e) => {
    const { onChange } = this.props;
    const newAttribute = { ...formAttribute };
    newAttribute[e.target.name] = e.target.value;

    onChange('formAttribute', newAttribute, event);
  };

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formAttribute } = this.props;

    onSubmit?.(formAttribute);
  }

  render() {
    const { t, onChange, formAttribute } = this.props;

    return (
      <div className="step-form" key={`${formAttribute.id}-${formAttribute.nonce}`}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                name="name"
                label="form.label.name"
                onChange={this.onChange}
                value={formAttribute.name}
              />
              {this.validator.message(t('form.label.name'), formAttribute.name, 'required')}
            </div>
            <div>
              <FormInput
                name="color"
                label="form.label.color"
                onChange={this.onChange}
                value={formAttribute.color}
              />
              {this.validator.message(t('form.label.color'), formAttribute.color, 'required')}
            </div>
            <div className="input-group more right">
              <input type="submit" disabled={!this.validator.allValid()} value={t('form.save')} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
