import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import { defaultDateTimeFormat } from '@utils/dateUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class UpdateActionTimesPage extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      action: props.action,
      startTime: props.action.startTime,
      endTime: props.action.endTime,
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { updateActionTimes, popStack, onSubmit } = this.props;
    const { action, startTime, endTime } = this.state;

    if (onSubmit) {
      onSubmit(action.id, startTime, endTime);
    } else {
      updateActionTimes(action.id, startTime, endTime);
    }
    popStack?.();
  }

  render() {
    const { t } = this.props;
    const { action, startTime, endTime } = this.state;

    if (!action) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('action.editTimes')}</h1>
        <div className="step-form" key={`${action.id}-${action.versionNumber}-${action.nonce}`}>
          <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="input-container">
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <input
                    type="datetime-local"
                    value={moment(startTime || +moment()).format('YYYY-MM-DDTHH:mm') || ''}
                    onChange={(e) => {
                      this.setState({
                        startTime: e.target.value ? moment(e.target.value) : moment(),
                      });
                    }}
                  />
                  <label>{t('form.label.startTime')}*</label>
                </div>
                {this.validator.message(t('form.label.startTime'), startTime, 'required')}
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <input
                    type="datetime-local"
                    value={moment(endTime || +moment()).format('YYYY-MM-DDTHH:mm') || ''}
                    onChange={(e) => {
                      this.setState({
                        endTime: e.target.value ? moment(e.target.value) : moment(),
                      });
                    }}
                  />
                  <label>{t('form.label.endTime')}*</label>
                </div>
                {this.validator.message(t('form.label.endTime'), endTime, 'required')}
              </div>

              <div className="input-group more right">
                <input
                  type="submit"
                  disabled={!this.validator.allValid()}
                  onClick={(e) => e.stopPropagation()}
                  value={t('form.save')}
                />
              </div>
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
