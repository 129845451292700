import React, { Component } from 'react';

import { Form } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import FormInput from '@uiinputs/FormInput';

import * as consignmentApi from '@api/consignmentApi';

import { distinct } from '@utils/arrayUtils';

// import { consignmentFilters } from '@/constants/filters';
import { CONSIGNMENTS } from '../../../constants/constants';
import Constraint from '../../../models/constraint/Constraint';
import Association from '../../../models/general/Association';
import ConsignmentCard from '../ConsignmentCard';
import ConsignmentSelectorFilterView from './ConsignmentSelectorFilterView';

let searchTimeout = null;

class ConsignmentSelectorView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingConsignments: false,
      filters: [],
      consignments: this.props.selectedConsignments ? this.props.selectedConsignments : [],
      consignmentsLoaded: 0,
      consignmentsTotalResults: 0,
      selectedConsignments: this.props.selectedConsignments ? this.props.selectedConsignments : [],
    };
  }

  onScroll(e) {
    const { consignmentsLoaded, consignmentsTotalResults } = this.state;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && consignmentsLoaded < consignmentsTotalResults) {
      this.search(this.state, consignmentsLoaded, 20);
    }
  }

  search = (state = this.state, offset = 0, amount = 20) => {
    const { filters } = state;
    this.setState({
      isFetchingConsignments: true,
    });
    consignmentApi.search(filters, offset, amount).then((response) => {
      this.setState({
        consignments:
          offset > 0 ? [...state.consignments, ...response.consignments] : response.consignments,
        consignmentsLoaded:
          offset > 0
            ? [...state.consignments, ...response.consignments].length
            : response.consignments.length,
        consignmentsTotalResults: response.totalResults,
        isFetchingConsignments: false,
      });
    });
  };

  onSearchChange = (query) => {
    this.search(this.state, 0, 20);
  };

  dragConsignmentStart = ({ target }) => {
    target.classList.add('fade-in');
    const { changeTripValue, dragEvent } = this.props;
    changeTripValue('dragEvent', { ...dragEvent, dragging: CONSIGNMENTS });
  };

  dragConsignmentEnd = ({ target }) => {
    const { createTrip, dragEvent, vehicles, changeTripValue } = this.props;

    target.classList.remove('fade-in');
    let consignment = this.state.consignments.filter((c) => c.id === target.id).shift();
    let location = (i) =>
      consignment?.actions[i].entity?.location?.entity?.administrativeReference || {
        city: '',
        country: '',
      };
    let name = `${location(0).city},${location(0).country} To  ${location(1).city},${
      location(1).country
    }`;
    if (dragEvent && dragEvent.targetVehicleId && dragEvent.targetDate) {
      let trip = {
        name: name,
        transportMode: 'road',
        documents: [],
        actions: consignment.actions,
        calculatingRoute: false,
        actionsHaveChanged: false,
        vehicle: dragEvent?.targetVehicleId
          ? vehicles.filter((v) => v.id === dragEvent.targetVehicleId).shift()
          : null,
      };
      const newConstraint = new Constraint();
      newConstraint.type = 'startDateTimeConstraint';
      newConstraint.value.type = 'startDateTimeConstraint';
      newConstraint.value.startDateTime = moment(
        dragEvent.targetDate,
        'DD/MM/YYYY[T]HH:mm:ss'
      ).format();
      trip.constraints = [new Association('inline ', { ...newConstraint })];

      createTrip(trip).then(() => {
        this.setState({
          consignments: [...this.state.consignments].filter((c) => c.id !== consignment.id),
        });
      });
    }
    changeTripValue('dragEvent', null);
  };

  render() {
    const { t, onChange, addToStack, popStack, isDraggable } = this.props;
    const { selectedConsignments, isFetchingConsignments, filters } = this.state;

    const consignments = distinct([...selectedConsignments, ...this.state.consignments], 'id');

    return (
      <div className="consignment-selector input-group">
        <div>
          <div className="flex-container no-wrap">
            <FormInput
              className="three"
              type="text"
              wrapperClass="no-margin-top"
              placeholder={t('form.label.searchConsignments')}
              value={filters.query}
              onChange={(event) => {
                const searchQuery = event.target.value ? event.target.value : null;

                this.setState({
                  filters: { ...filters, query: searchQuery },
                });
                if (searchTimeout) clearTimeout(searchTimeout);
                searchTimeout = setTimeout(() => {
                  if (this.onSearchChange) this.onSearchChange(searchQuery);
                }, 400);
              }}
            />
            <div className="action-icons">
              <div
                className={`action-icon${
                  Object.values({ ...filters }).filter((f) => f != null).length > 0 ? ' active' : ''
                }`}
                data-content={Object.values(filters).filter((f) => f != null).length}
                onClick={(e) => {
                  e.preventDefault();
                  addToStack({
                    name: t('form.label.advancedFilters'),
                    component: (
                      <ConsignmentSelectorFilterView
                        filters={filters}
                        onChange={(newFilters) => {
                          this.setState({
                            filters: newFilters,
                          });
                          popStack?.();
                        }}
                      />
                    ),
                  });
                }}
              >
                <ReactSVG src="/icons/filter.svg" />
              </div>
            </div>
          </div>
        </div>
        {isFetchingConsignments && consignments.length < 1 ? (
          <Loader />
        ) : consignments.length > 0 ? (
          <div className="consignment-results scrollable" onScroll={(e) => this.onScroll(e)}>
            {consignments.map((consignment) => {
              const isSelected = selectedConsignments
                .map((selectedConsignment) => selectedConsignment.id)
                .includes(consignment.id);
              return (
                <div
                  className="flex-container"
                  key={`consignment-${consignment.id}-${isSelected ? '-selected' : ''}`}
                >
                  {!isDraggable && (
                    <div className="self-center select">
                      <input
                        type="checkbox"
                        id={`consignment-select-${consignment.id}`}
                        checked={isSelected}
                        onChange={(e) => {
                          this.setState(
                            {
                              selectedConsignments: isSelected
                                ? selectedConsignments.filter(
                                    (selectedConsignment) =>
                                      selectedConsignment.id !== consignment.id
                                  )
                                : [...selectedConsignments, consignment],
                            },
                            () => {
                              onChange(this.state.selectedConsignments);
                            }
                          );
                        }}
                      />
                    </div>
                  )}

                  <label htmlFor={`consignment-select-${consignment.id}`}>
                    <ConsignmentCard
                      dragStart={this.dragConsignmentStart}
                      dragEnd={this.dragConsignmentEnd}
                      consignment={consignment}
                      isDraggable={isDraggable}
                    />
                  </label>
                </div>
              );
            })}
            {isFetchingConsignments && <Loader />}
          </div>
        ) : (
          <NoData className="center">{t('consignments.notFound')}</NoData>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(ConsignmentSelectorView);
