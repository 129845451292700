import React, { Component } from 'react';

import Select from 'react-select';

import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export const unitOptions = [
  { value: 'consignment', label: 'consignment' },
  { value: 'good', label: 'good' },
  { value: 'mm', label: 'millimeter' },
  { value: 'cm', label: 'centimeter' },
  { value: 'm', label: 'meter' },
  { value: 'km', label: 'kilometer' },
  { value: 'mi', label: 'mile' },
  { value: 'm2', label: 'square meter' },
  { value: 'l', label: 'liter' },
  { value: 'kg', label: 'kilogram' },
  { value: 'g', label: 'gram' },
  { value: 'lbs', label: 'pound' },
  { value: 't', label: 'metric ton' },
];

export default class ValueWithUnitInput extends Component {
  static defaultProps = {
    units: unitOptions.map((u) => u.value),
    value: null,
  };

  state = {
    value: this.props.value ? this.props.value.value : null || null,
    unit: this.props.value ? this.props.value.unit : null || null,
  };

  componentDidUpdate() {
    activateInputs();
  }

  componentDidMount() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { onChange, t, label, units, defaultUnit, hiddenTypeFields } = this.props;
    const { value, unit } = this.state;

    return (
      <div className="input-group">
        <div className="input-group eighty no-margin-top">
          <FormInput
            type="text"
            label={label}
            hiddenTypeFields={hiddenTypeFields}
            value={value || ''}
            wrapperClass="no-margin-top"
            onChange={(e) => {
              this.setState(
                {
                  value: e.target.value,
                  unit: unit || defaultUnit,
                },
                () => {
                  onChange(this.state);
                }
              );
            }}
          />
        </div>
        <div className="input-group fifth no-margin-top">
          <Select
            className=""
            placeholder={t('form.label.unit')}
            noOptionsMessage={() => t('noOptions')}
            options={unitOptions.filter((unitOption) => units.includes(unitOption.value))}
            value={
              unitOptions.find((unitOption) => unitOption.value === unit) ||
              unitOptions.find((unitOption) => unitOption.value === defaultUnit)
            }
            onChange={(e) => {
              this.setState(
                {
                  unit: e.value,
                },
                () => {
                  onChange(this.state);
                }
              );
            }}
          />
        </div>
      </div>
    );
  }
}
