import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EntityTodoSection from '@components/todo/EntityTodoSection';

import * as todoActions from '@actions/todoActions';

class EntityTodosContainer extends Component {
  render() {
    return <EntityTodoSection {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.todo,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || "DD/MM/YYYY",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTodo: (todo) => dispatch(todoActions.getTodo(todo)),

    getEntityTodos: (entityType, entityId, offset, amount) =>
      dispatch(todoActions.getEntityTodos(entityType, entityId, offset, amount)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(EntityTodosContainer));
