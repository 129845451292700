import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactSVG from 'react-svg';

import Loader from '@uicomponents/Loader';
import FormInput from '@uiinputs/FormInput';

import Chat from '@models/chat/Chat';
import ChatUser from '@models/chat/ChatUser';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import CreateMessageForm from './CreateMessageForm';

export default class CreateChatPage extends Component {
  state = { inputValue: '' };
  searchTimeout = null;

  componentDidMount() {
    const { onChange } = this.props;
    activateInputs();

    onChange('chat', new Chat());
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit() {
    const { chat, message, createChat } = this.props;
    chat.users.length > 0 && createChat(chat, message);
  }

  render() {
    const { t, onChange, chat, profiles, isFetching, searchUsers, chatPopup, maximizeNewChat } =
      this.props;
    const { Option } = components;

    if (isFetching) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    const SelectOption = (props) => (
      <Option {...props} className="select-option">
        <img src={props.data.avatar} alt={props.data.label} />
        <span>{props.data.label}</span>
      </Option>
    );
    const SelectValue = (props) => (
      <div className="select-value">
        <img src={props.data.avatar} alt={props.data.label} />
        {props.data.label}
      </div>
    );

    if (chat) {
      return (
        <div className="main">
          <div className="chat-header">
            <h2>{t('chat.new')}</h2>
            <div className="icons">
              {chatPopup && (
                <>
                  {maximizeNewChat ? (
                    <div
                      className="circle-icon-primary circle-md svg-lg"
                      onClick={() => onChange('maximizeNewChat', false)}
                    >
                      <ReactSVG src="/icons/arrow-down.svg" />
                    </div>
                  ) : (
                    <div
                      className="circle-icon-primary circle-md svg-lg"
                      onClick={() => onChange('maximizeNewChat', true)}
                    >
                      <ReactSVG src="/icons/arrow-up.svg" />
                    </div>
                  )}
                  <div
                    className="circle-icon-primary circle-md svg-lg"
                    onClick={() => onChange('newChat', false)}
                  >
                    <ReactSVG src="/icons/close.svg" />
                  </div>
                </>
              )}
            </div>
          </div>
          {!chatPopup || (chatPopup && maximizeNewChat) ? (
            <div className="create-chat">
              <form className="no-padding">
                {chat.users.length > 1 && (
                  <FormInput
                    type="text"
                    label="form.label.groupName"
                    value={chat.title}
                    onChange={(event) => {
                      const newChat = { ...chat };
                      newChat.title = event.target.value;
                      onChange('chat', newChat);
                    }}
                  />
                )}
                <div className="input-group">
                  <AsyncSelect
                    isMulti={true}
                    components={{
                      Option: SelectOption,
                      SingleValue: SelectValue,
                    }}
                    noOptionsMessage={() => t('users.notFound')}
                    placeholder={t('form.label.addPeople')}
                    loadingMessage={() => t('loading')}
                    loadOptions={(inputValue, callback) => {
                      if (this.searchTimeout) clearTimeout(this.searchTimeout);
                      this.searchTimeout = setTimeout(() => {
                        searchUsers({ query: inputValue }, 0, 20).then((data) => {
                          callback(
                            data.users.map((user) => {
                              return {
                                value: user.id,
                                label: `${user.firstName} ${user.lastName}`,
                                avatar: user.avatar ? user.avatar.url : '/images/user.svg',
                              };
                            })
                          );
                        });
                      }, 800);
                    }}
                    onChange={(e) => {
                      const newChat = { ...chat };
                      if (e && e.length > 0) {
                        const chatUsers = e.map((el) => {
                          const chatUser = new ChatUser();
                          chatUser.user = el.value;
                          return chatUser;
                        });
                        newChat.users = chatUsers;
                      } else {
                        newChat.users = [];
                      }
                      onChange('chat', newChat);
                    }}
                  />
                </div>
              </form>
              <CreateMessageForm createMessage={() => this.handleSubmit()} {...this.props} />
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  }
}
