import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import MessageAutomation from '@models/messageAutomation/messageAutomation';

import MessageAutomationForm from './MessageAutomationForm';
import MessageAutomationSelectionForm from './MessageAutomationSelectionForm';

export default class CreateMessageAutomationPage extends Component {
  componentDidMount() {
    const { onChange, targetName, messageAutomationTemplate } = this.props;
    if (messageAutomationTemplate) {
      onChange('formMessageAutomation', messageAutomationTemplate);
    } else {
      const newMessageAutomation = new MessageAutomation();
      newMessageAutomation.name = targetName || '';
      onChange('formMessageAutomation', newMessageAutomation);
    }
  }

  render() {
    const {
      t,
      formMessageAutomation,
      isFetchingMessageAutomation,
      createMessageAutomation,
      popStack,
      isMessageAutomationTemplate,
      defaultForm,
    } = this.props;

    if (!formMessageAutomation || isFetchingMessageAutomation) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>
          {isMessageAutomationTemplate
            ? t('messageAutomationTemplate.new')
            : t('messageAutomation.new')}
        </h1>
        {isMessageAutomationTemplate ? (
          <MessageAutomationForm
            {...this.props}
            key={formMessageAutomation.id}
            onSubmit={(messageAutomation) => createMessageAutomation(messageAutomation)}
          />
        ) : defaultForm ? (
          <MessageAutomationForm
            {...this.props}
            key={formMessageAutomation.id}
            onSubmit={(messageAutomation) => createMessageAutomation(messageAutomation)}
          />
        ) : (
          <MessageAutomationSelectionForm {...this.props} />
        )}
      </AsideView>
    );
  }
}
