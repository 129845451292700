import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import CountrySelector from '@uicomponents/CountrySelector';
import FormInput from '@uiinputs/FormInput';

import Address from '@models/general/Address';

class AddressInput extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {
      address: this.props.address || new Address(),
    };
  }

  updateAddress = (newAddress) => {
    const { onChange } = this.props;
    this.setState({
      address: newAddress,
    });
    onChange && onChange(newAddress);
  };

  render() {
    const { address } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="input-group no-margin-top">
          <div className="input-group  no-margin-top sixty">
            <FormInput
              type="text"
              label="form.label.street"
              required={true}
              value={address.street}
              onChange={(event) => {
                const newAddress = { ...address };
                newAddress.street = event.target.value;

                this.updateAddress(newAddress);
              }}
            />
            {this.validator.message(t('form.label.street'), address.street, 'required')}
          </div>
          <div className="input-group  no-margin-top fourty">
            <FormInput
              type="text"
              label="form.label.houseNumber"
              required={true}
              value={address.houseNumber}
              onChange={(event) => {
                const newAddress = { ...address };
                newAddress.houseNumber = event.target.value;

                this.updateAddress(newAddress);
              }}
            />
            {this.validator.message(t('form.label.houseNumber'), address.houseNumber, 'required')}
          </div>
          <div className="input-group  no-margin-top half">
            <FormInput
              type="text"
              label="form.label.zipCode"
              required={true}
              value={address.postalCode}
              onChange={(event) => {
                const newAddress = { ...address };
                newAddress.postalCode = event.target.value;

                this.updateAddress(newAddress);
              }}
            />
            {this.validator.message(t('form.label.zipCode'), address.zipCode, 'required')}
          </div>
          <div className="input-group  no-margin-top half">
            <FormInput
              type="text"
              label="form.label.city"
              value={address.city}
              required={true}
              onChange={(event) => {
                const newAddress = { ...address };
                newAddress.city = event.target.value;

                this.updateAddress(newAddress);
              }}
            />
            {this.validator.message(t('form.label.city'), address.city, 'required')}
          </div>
          <div className="input-group">
            <CountrySelector
              placeholder={`${t('form.label.selectCountry')}*`}
              value={address.country}
              onChange={(value) => {
                const newAddress = { ...address };
                newAddress.country = value.value;

                this.updateAddress(newAddress);
              }}
            />
            {this.validator.message(t('form.label.country'), address.country, 'required')}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(AddressInput);
